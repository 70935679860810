export const addBuildingVisuals = [
  {
    name: "Demo",
    markdown: `![Add building GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];
export const addSurfaceCoveringVisuals = [
  {
    name: "Demo",
    markdown: `![Add surface covering GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const addLandCovering = {
  order: 17,
  name: "Add a land covering",
  keywords: "building parking lot surface",
  subcategory: "Properties",
  markdown: `# Add a land covering

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  In Tower Hunt, land coverings are the physical improvements that get built on land. There are two types of land coverings: buildings and surface coverings (ex. parking lots). Use buildings when you are representing a structure with a vertical aspect &mdash; either above- or below-grade. Use surface coverings when the structure just covers the surface of the land, for example with a parking lot, industrial outdoor storage, or solar installation.

  {% callout type="tip" %}
  **Tip:** A land covering can span multiple fee parcels. Conversely, multiple land coverings can sit atop one fee parcel.
  {% /callout %}

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Property selector" %}property{% /inlineRouterLink %} or {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Fee parcels" %}fee parcel{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="addBuilding" /%} for a building or {% inlinePropertyDiagramIcon name="addSurfaceStructure" /%} for a surface covering.
  4. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" sectionName="Placing new datapoints on the diagram" %}**Select**{% /inlineRouterLink %} one or more {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Fee parcels" %}fee parcels{% /inlineRouterLink %} to place the land covering on.
  5. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. A new land covering appears in the {% inlineRouterLink articleId="visual-features-of-the-property-diagram" sectionName="Land coverings" %}land coverings section of the diagram{% /inlineRouterLink %}.
  {% /tablessContainer %}

  ## Visuals

  ### Buildings

  {% tabbedContainer tabs=$addBuildingVisuals /%}

  ### Surface coverings

  {% tabbedContainer tabs=$addSurfaceCoveringVisuals /%}
  `,
};
